// This is the scss entry file
import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
// import "bootstrap/dist/js/bootstrap.bundle";

// We can import other JS file as we like
// import "../components/sidebar";

// mobile and desktop hambuger bars toggle
const mobileNavigation = document.querySelector('[data-mobile-navigation]');
const body = document.querySelector('body');
const mobileNavigationToggle = document.querySelector(
  '[data-mobile-navigation-toggle]',
);
const mobileCloseNavigationToggle = document.querySelector(
  '[data-mobile-close-navigation]',
);
// const sidebar = document.querySelector(
//   '.sidebar',
// );
const searchOpenButton = document.querySelector('#search-open');
const searchCloseButton = document.querySelector('#search-close-btn');
const search = document.querySelector('#search');
const searchInput = document.querySelector('#search-input');

function openMobileNavigation() {
  if (mobileNavigation.hidden) {
    body.classList.add('no-scroll');
    mobileNavigation.hidden = false;
    mobileNavigationToggle.setAttribute('aria-expanded', 'true');
  }

}
function closeMobileNavigation() {
  body.classList.remove('no-scroll');
  mobileNavigation.hidden = true;
  mobileNavigationToggle.setAttribute('aria-expanded', 'false');
}

function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}


const header = document.querySelector('header');
const footer = document.querySelector('footer');
const contentWrapper = document.querySelector('#content-wrapper');

function applyContentHeight() {
  const headerHeight = header.offsetHeight;
  const footerHeight = footer.offsetHeight;

  contentWrapper.style.minHeight = (window.innerHeight - headerHeight - footerHeight) + 'px';
}

// function checkScrollDistance() {
//   const scrollDistanceFromTop = window.scrollY;
//   const totalHeight = document.documentElement.scrollHeight;
//   const windowHeight = window.innerHeight;
//   const scrollDistanceFromBottomFooter = totalHeight - footer.offsetHeight - (scrollDistanceFromTop + windowHeight);
//   const threshold = 95;
//   const bottomDistance = 20;

//   if (sidebar.clientHeight <  window.innerHeight - header.offsetHeight - footer.offsetHeight) {
//     if (scrollDistanceFromTop > threshold) {
//       if (!sidebar.classList.contains('sidebar-fixed')) {
//         sidebar.classList.add('sidebar-fixed');
//         sidebar.classList.add('sidebar-fixed-top');
//       }
//     } else {
//       sidebar.classList.remove('sidebar-fixed');
//       sidebar.classList.remove('sidebar-fixed-top');
//     }
//   }

//   if (sidebar && totalHeight - windowHeight > 300) {
//     if (scrollDistanceFromTop > threshold) {
//       if (!sidebar.classList.contains('sidebar-fixed')) {
//         sidebar.classList.add('sidebar-fixed');
//       }
//       if (scrollDistanceFromBottomFooter <= bottomDistance) {
//         sidebar.classList.add('sidebar-bottom-10');
//       } else {
//         sidebar.classList.remove('sidebar-bottom-10');
//       }
//     } else {
//       sidebar.classList.remove('sidebar-fixed');
//       sidebar.classList.remove('sidebar-bottom-10');
//     }
//   }
// }

// share popover
document.addEventListener('DOMContentLoaded', () => {
  applyContentHeight();
  mobileNavigationToggle.addEventListener('click', () => {
    openMobileNavigation();
  });
  mobileCloseNavigationToggle.addEventListener('click', () => {
    closeMobileNavigation();
  });
  window.addEventListener('resize', throttle(function () {
    applyContentHeight();
  }, 100));
  searchOpenButton.addEventListener('click', ()=> {
    search.hidden = false;
    searchInput.focus();
  });
  searchCloseButton.addEventListener('click', (e)=> {
    e.preventDefault();
    search.hidden = true;
  });
});
